import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import Header from './Header';

const Layout = ({ children }) => {
  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Wisteria Software</title>
      <link rel="canonical" href="https://wisteriasoftware.com" />
    </Helmet>
      <Header />
      <main className="text-gray-900">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
